<template>
    <div style="margin: 0 10px;">
        <div v-html="data"></div>
    </div>
</template>

<script>
import {
  defineComponent,reactive,store
} from "@/plugin/importCommonUse";
import { useRouter } from "vue-router";
import moduleHead from "@/components/moduleHead/index.vue"
export default defineComponent({
    components:{
        moduleHead
    },
    setup() {
        let router = useRouter();
        let data = localStorage.getItem('list_detailsHTML');
        let skip = (url)=>{
            router.push(url);
        }
        return {
            skip,data
        };
    },
})
</script>
<style>
</style>
